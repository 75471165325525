<template>
  <div>
    首页

  </div>
</template>

<script>
export default {
  name: 'ChatPage',
  data() {
    return {
      description: '首页'

    }
  }
}
</script>
<style lang="less" scoped></style>